import axios from "axios";
import encryptStorage from "./encryptStorage";


var HTTPRequest = {
    async getRequest(endPoint, path, parameters) {
        var response_data;
        await axios({
            method: "get",
            url: endPoint+path,
            headers: { Authorization: "Bearer " + encryptStorage.getItem("accessToken"), "Access-Control-Allow-Origin": "*"},
            params: parameters
        })
        .then(function (response) {
            response_data = response.data
        })
        .catch(async function (error) {
            if (error.response.status == 401) {
                var auth_response = await HTTPRequest.authenticate(endPoint)
                if (auth_response["status"] == "PROCEED") {
                    await HTTPRequest.getRequest(endPoint, path, parameters)
                } else {
                    response_data = {error: "Cannot get new access token"}
                }
            } else {
                //Session Expired
                response_data = {error: "ELSE this is an error"}
            }
        })

        return response_data;
    },
    async postRequest(endPoint, path, parameters, requestBody) {
        var response_data;
        await axios({
            method: "post",
            url: endPoint+path,
            headers: { Authorization: "Bearer " + encryptStorage.getItem("accessToken"), "Access-Control-Allow-Origin": "*"},
            data: requestBody,
        })
        .then(function (response) {
            response_data = response.data
        })
        .catch(async function (error) {
            if (error.response.status == 401) {
                var auth_response = await HTTPRequest.authenticate(endPoint)
                if (auth_response["status"] == "PROCEED") {
                    await HTTPRequest.postRequest(endPoint, path, parameters, requestBody)
                } else {
                    response_data = {error: "Cannot get new access token"}
                }
            } else {
                //Session Expired
                response_data = {error: "ELSE this is an error"}
            }
        })

        return response_data;
    },
    async putRequest(endPoint, path, parameters, requestBody) {
        var response_data;
        await axios({
            method: "put",
            url: endPoint+path,
            headers: { Authorization: "Bearer " + encryptStorage.getItem("accessToken"), "Access-Control-Allow-Origin": "*"},
            data: requestBody,
        })
        .then(function (response) {
            response_data = response.data
        })
        .catch(async function (error) {
            if (error.response.status == 401) {
                var auth_response = await HTTPRequest.authenticate(endPoint)
                if (auth_response["status"] == "PROCEED") {
                    await HTTPRequest.putRequest(endPoint, path, parameters, requestBody)
                } else {
                    response_data = {error: "Cannot get new access token"}
                }
            } else {
                //Session Expired
                response_data = {error: "ELSE this is an error"}
            }
        })

        return response_data;
    },
    async destroyRequest(endPoint, path, parameters, requestBody) {
        var response_data;
        await axios({
            method: "delete",
            url: endPoint+path,
            headers: { Authorization: "Bearer " + encryptStorage.getItem("accessToken"), "Access-Control-Allow-Origin": "*"},
            data: requestBody,
        })
        .then(function (response) {
            response_data = response.data
        })
        .catch(async function (error) {
            if (error.response.status == 401) {
                var auth_response = await HTTPRequest.authenticate(endPoint)
                if (auth_response["status"] == "PROCEED") {
                    await HTTPRequest.destroyRequest(endPoint, path, parameters, requestBody)
                } else {
                    response_data = {error: "Cannot get new access token"}
                }
            } else {
                //Session Expired
                response_data = {error: "ELSE this is an error"}
            }
        })

        return response_data;
    },
    async authenticate(endPoint) {
        var response_data;
        var bodyFormData = new FormData;
        bodyFormData.set("email", encryptStorage.getItem("email"));
        bodyFormData.set("app_type", "v1-web-app");
        bodyFormData.append("grant_type", "refresh_token");
        bodyFormData.append("refresh_token", encryptStorage.getItem("refreshToken"));

        await axios({
            method: "post",
            url: endPoint + "/api/v1/auth/token",
            headers: {"Access-Control-Allow-Origin": "*"},
            data: bodyFormData,
        })
        .then(function (response) {
            response_data = response.data;
            encryptStorage.setItem("accessToken", response_data["access_token"]),
            encryptStorage.setItem("refreshToken", response_data["refresh_token"]),
            response_data = {status: "PROCEED"}
        })
        .catch(function (error) {
            response_data = {error: "Error"}
        })

        return response_data;
    }
}

export default HTTPRequest;